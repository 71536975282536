<template>
  <div ref="MainContent" class="relative">
    <div
      class="absolute z-0 overflow-hidden text-white transform -translate-x-1/2 translate-y-5 md:translate-y-10 w-72 md:left-auto left-1/2 md:translate-x-0 md:right-10 top-full"
    >
      <div
        data-dialog-box
        class="relative z-10 flex items-center justify-between px-5 py-4 mx-auto overflow-hidden transform border border-black rounded-sm shadow-md bg-primary"
      >
        <div class="relative z-10 w-full text-white">
          <div class="flex items-center justify-between pb-2 mb-3">
            <p class="text-sm">The item has been added to your shopping cart</p>

            <button @click="CloseShopCartDialog" class="relative z-10 block">
              <i class="text-sm text-white icon-close"></i>
            </button>
          </div>
          <button
            @click="OpenShopCart"
            class="block w-full py-3 text-xs text-center text-white bg-black border border-black rounded-sm cursor-pointer"
          >
            SHOP CART
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ShopCartMessage } from "@/gsap/ShopCartMessage.js";
export default {
  name: "ShopCartMessage",
  data() {
    return {
      dialog_timer: null,
      dialog_gsap: null,
    };
  },
  methods: {
    OpenShopCart() {
      this.$store.commit("SetShopCartStatus", 1);
    },
    CloseShopCartDialog() {
      this.$store.commit("SetShopCartDialog", -99999);
    },
  },
  computed: {
    shop_cart_dialog() {
      return this.$store.state.shop_cart_dialog;
    },
  },
  watch: {
    shop_cart_dialog() {
      if (this.shop_cart_dialog > 0) {
        this.dialog_gsap.open();
      } else {
        this.dialog_gsap.close();
      }
    },
  },
  mounted() {
    this.dialog_gsap = new ShopCartMessage(this.$refs.MainContent);
    this.dialog_gsap.reset();
  },
};
</script>
