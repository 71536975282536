<template>
  <main class="relative w-full">
    <Carousel class="mb-10" />
    <div
      class="w-full h-16 mb-10 opacity-50 marquee_bg"
      style="
        background-image: url('/img/marquee_logo.svg');
        background-repeat: repeat-x;
        background-size: 1250px 64px;
      "
    ></div>
    <MainProduct />
    <SecondProduct />
    <ProductCollections />
    <div
      class="w-full h-16 mb-10 opacity-50 marquee_bg"
      style="
        background-image: url('/img/marquee_logo.svg');
        background-repeat: repeat-x;
        background-size: 1250px 64px;
      "
    ></div>
    <About />
    <NewsList />
    <SocialFooter />
  </main>
</template>

<script>
import Carousel from "@/components/home/Carousel.vue";
import MainProduct from "@/components/home/MainProduct.vue";
import SecondProduct from "../components/home/SecondProduct.vue";
import ProductCollections from "../components/home/ProductCollections.vue";
import About from "../components/home/About.vue";
import NewsList from "../components/home/NewsList.vue";
import SocialFooter from "@/components/home/SocialFooter.vue";

export default {
  name: "HomeView",
  components: {
    Carousel,
    MainProduct,
    SecondProduct,
    ProductCollections,
    About,
    NewsList,
    SocialFooter,
  },
  created() {
    const meta_data = this.$store.getters.page_meta_data("首頁");
    this.$PageInit(
      this,
      meta_data.Title,
      meta_data.Content,
      meta_data.Image == "" ? "" : this.$ImageUrl(meta_data.Image)
    );
  },
};
</script>
