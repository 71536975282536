<template>
  <div ref="imageContainer" class="lazyImageContainer">
    <img
      v-if="isIntersecting"
      :src="src"
      :alt="alt"
      @load="onLoad"
      @error="onError"
    />
    <div v-else class="placeholder"></div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isIntersecting: false,
      isLoaded: false,
      error: false,
      observer: null,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersect, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });
    this.observer.observe(this.$refs.imageContainer);
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    handleIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.isIntersecting = true;
        // 一旦圖片開始加載，我們就停止觀察
        if (this.observer) {
          this.observer.unobserve(this.$refs.imageContainer);
          this.observer.disconnect();
        }
      }
    },
    onLoad() {
      this.isLoaded = true;
      this.$emit("load");
    },
    onError() {
      this.error = true;
      this.$emit("error");
    },
  },
};
</script>

<style>
.lazyImageContainer img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
</style>
