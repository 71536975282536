import { gsap } from "@/gsap/GsapLoader";

export class AnnouncementMarquee {
  constructor(el) {
    this.el = el;
    this.announcement_items = el.querySelectorAll("p");

    this.timeline = null;
    this.timer = null;
    this.active_index = 0;
  }

  reset() {
    gsap.set(this.announcement_items, { x: "0%", y: "0%" });
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = null;
  }

  setup() {
    if (this.announcement_items.length > 1) {
      this.reset();
      this.timer = setTimeout(() => {
        this.run();
      }, 5000);
    } else {
      // gsap.set(this.announcement_items, { y: "-100%" });
    }
  }

  run() {
    this.timer != null ? clearTimeout(this.timer) : "";
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline();

    let next_index =
      this.active_index == this.announcement_items.length - 1
        ? 0
        : this.active_index + 1;

    this.timeline.fromTo(
      this.announcement_items,
      {
        y: "0%",
      },
      {
        y: "-100%",
      },
      "out"
    );
    this.timeline.set(
      this.announcement_items,
      {
        x: next_index * -100 + "%",
        y: "100%",
      },
      "move"
    );
    this.timeline.to(
      this.announcement_items,
      {
        y: "0%",
        onComplete: () => {
          this.active_index = next_index;
          this.timer = setTimeout(() => {
            this.run();
          }, 5000);
        },
      },
      "in"
    );
  }
}
