<template>
  <div
    ref="MainContent"
    class="fixed top-0 z-30 flex items-center justify-center w-full h-screen left-full"
  >
    <div
      data-dialog-box
      class="relative z-10 w-11/12 max-w-md px-6 py-6 text-white bg-zinc-900"
    >
      <p class="mb-5 text-2xl font-medium">訊息</p>
      <p class="mb-10 text-zinc-500" v-html="dialog_content"></p>
      <div class="flex justify-center w-full">
        <button
          @click="Close"
          class="inline-flex items-center justify-between w-full px-5 py-4 text-sm font-bold text-white transition-colors duration-500 bg-opacity-50 border border-black md:pl-8 md:pr-6 md:py-2 md:w-auto sm:text-base bg-primary md:hover:bg-opacity-100"
        >
          關閉
          <i class="block ml-3 icon-close"></i>
        </button>
      </div>
    </div>
    <div
      data-dialog-background
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-black bg-opacity-50"
    ></div>
  </div>
</template>

<script>
import { Dialog } from "@/gsap/MainDialog";
export default {
  name: "MainDialog",
  data() {
    return {
      dialog_gsap: null,
    };
  },
  methods: {
    Close() {
      this.$store.commit("SetDialog", {
        status: false,
        content: this.dialog_content,
      });
    },
  },
  watch: {
    dialog_status() {
      this.dialog_status ? this.dialog_gsap.open() : this.dialog_gsap.close();
    },
  },
  computed: {
    dialog_status() {
      return this.$store.state.main_dialog.status;
    },
    dialog_content() {
      return this.$store.state.main_dialog.content;
    },
  },
  mounted() {
    this.dialog_gsap = new Dialog(this.$refs.MainContent);
    this.dialog_gsap.reset();
  },
};
</script>
