<template>
  <section ref="MainContent" class="relative my-20 flex items-center h-[500px]">
    <div
      data-section-content
      class="relative z-10 max-w-screen-md px-5 mx-auto text-center"
    >
      <p class="mb-0 text-lg font-bold text-primary">WHO WE ARE</p>
      <div class="relative mb-5">
        <h3 class="text-3xl font-black text-white sm:text-5xl">
          {{ $GetColumn("home_about_title") }}
        </h3>
      </div>
      <div
        class="mb-10 text-sm text-zinc-300"
        v-html="$GetColumn('home_about_content').replace(/\r?\n/g, '<br />')"
      ></div>
      <div class="flex items-center justify-center">
        <a
          href="/about"
          class="inline-flex items-center py-2 pl-8 pr-6 text-sm font-bold text-white transition-colors duration-500 bg-opacity-100 border border-black sm:text-base bg-primary md:hover:bg-opacity-50"
          >ABOUT US
          <i class="block ml-3 transform -rotate-45 icon-arrow-right"></i>
        </a>
      </div>
    </div>

    <div class="absolute top-0 bottom-0 left-0 right-0 z-0">
      <div
        class="absolute top-0 bottom-0 left-0 right-0 z-10 bg-black opacity-80"
      ></div>
      <div
        class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-center bg-cover"
        :style="`background-image: url('${$ImageUrl(
          $GetColumn('home_about_image')
        )}')`"
      ></div>
    </div>
  </section>
</template>

<script>
import { MainProductSection } from "@/gsap/home/MainProductSection";
export default {
  name: "HomeAboutSection",
  data() {
    return {
      section_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.section_gsap.reset();
      this.section_gsap.setup();
    },
  },
  mounted() {
    this.section_gsap = new MainProductSection(this.$refs.MainContent);
    this.section_gsap.reset();
    this.section_gsap.setup();
  },
};
</script>
